import React from "react";
import PropTypes from "prop-types";
import { Link, StaticQuery, graphql } from "gatsby";
import { FormattedMessage } from "react-intl";
import Img from "gatsby-image";

import Layout from "components/Layout";
import SEO from "components/Seo";
import Card from "components/Card";
import { IntlProvider } from "contexts/intl/IntlContext";
import { externalLink } from "constants/paths";

const queryImage = graphql`
  query {
    shahi_sawari_logo: file(relativePath: { eq: "shahi-sawari-logo.png" }) {
      childImageSharp {
        fixed(width: 96, height: 96) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    patari_logo: file(relativePath: { eq: "patari-logo.png" }) {
      childImageSharp {
        fixed(width: 96, height: 96) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    microsoft_logo: file(relativePath: { eq: "microsoft-logo.png" }) {
      childImageSharp {
        fixed(width: 96, height: 96) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const Projects = ({ location }) => (
  <IntlProvider pathname={location.pathname}>
    {({ locale, formatMessage, getPath }) => (
      <Layout>
        <SEO
          lang={locale}
          title={formatMessage({ id: "work.title" })}
          description={formatMessage({ id: "meta.description" })}
          keywords={formatMessage({ id: "meta.keywords" })}
        />
        <section className="section is-size-4-desktop is-size-5-touch">
          <h1 className="title has-text-centered has-text-light">
            <FormattedMessage id="work.title" />
          </h1>
          <div className="container">
            <hr />
            <div className="columns">
              <div className="column is-half is-offset-one-quarter">
                <StaticQuery
                  query={queryImage}
                  render={(data) => (
                    <>
                      <Card
                        title={formatMessage({ id: "work.microsoft.title" })}
                        subtitle={formatMessage({
                          id: "work.microsoft.role",
                        })}
                        link={externalLink.microsoft}
                        tags={["C++", "Objective-C"]}
                        image={
                          <Img
                            fixed={data.microsoft_logo.childImageSharp.fixed}
                            alt={formatMessage({
                              id: "work.microsoft.imageAlt",
                            })}
                          />
                        }
                      />

                      <Card
                        title={formatMessage({ id: "work.shahi_sawari.title" })}
                        subtitle={formatMessage({
                          id: "work.shahi_sawari.role",
                        })}
                        link={externalLink.shahi_sawari}
                        tags={[
                          "Node.js",
                          "Flask",
                          "Python",
                          "MongoDB",
                          "MySQL",
                        ]}
                        image={
                          <Img
                            fixed={data.shahi_sawari_logo.childImageSharp.fixed}
                            alt={formatMessage({
                              id: "work.shahi_sawari.imageAlt",
                            })}
                          />
                        }
                      />

                      <Card
                        title={formatMessage({ id: "work.patari.title" })}
                        subtitle={formatMessage({ id: "work.patari.role" })}
                        link={externalLink.patari}
                        tags={[
                          "Node.js",
                          "Express",
                          "AngularJS",
                          "MongoDB",
                          "Python",
                          "Djagno",
                        ]}
                        image={
                          <Img
                            fixed={data.patari_logo.childImageSharp.fixed}
                            alt={formatMessage({
                              id: "work.patari.imageAlt",
                            })}
                          />
                        }
                      />
                    </>
                  )}
                />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )}
  </IntlProvider>
);

Projects.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default Projects;
